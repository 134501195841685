import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import { useTheme } from '@mui/material';

export default makeStyles(() => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  return createStyles({
    root: { display: 'flex', gap: 16, flexDirection: 'column' },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 12,
      gap: 12,
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 12,
      gap: 12,
    },
    table: {
      '& .MuiTableContainer-root': {
        borderRadius: 4,
      },
      '& .MuiTableCell-head': {
        backgroundColor: darkMode ? grey[800] : grey[300],
      },
      '& .MuiTableRow-root': {
        backgroundColor: darkMode ? grey[700] : grey[200],
      },
    },
  });
});
