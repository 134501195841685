import Dashboard from './dashboard';
import Add from './add';
import Search from './search';
import View from './view';

export default {
  Dashboard,
  Add,
  Search,
  View,
};
