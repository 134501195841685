import React, { ReactNode, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { AutocompleteProps, Box, Chip, CircularProgress, Tooltip, Typography } from '@mui/material';
import latinize from 'latinize';
import Highlighter from 'react-highlight-words';
import { useDebounce } from 'use-debounce';

import Axios, { Canceler } from 'axios';
import api from '../../../services/api';
import styles from './styles';
import ProfileTooltip from '../../ProfileTooltip';
import { avatarUrl } from '../../../utils/functions';

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export type ValueProps = {
  id: string;
  description: string;
  subdescription?: string;
  [key: string]: string | number;
};

type ResponseType = {
  total: number;
  limit: number;
  page_current: number;
  page_total: number;
  results: ValueProps[];
};

export interface InputSearchAsyncProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'multiple' | 'value' | 'event' | 'onChange' | 'defaultValue' | 'options' | 'renderInput' | 'renderTags'
  > {
  // eslint-disable-next-line no-unused-vars
  onChange: (value: ValueProps[] | ValueProps | any) => void | undefined;
  value?: ValueProps[] | ValueProps | string | number;
  source:
    | 'intranet-users'
    | 'helpdesk-id'
    | 'helpdesk-target'
    | 'helpdesk-task'
    | 'erp-orders'
    | 'erp-customers'
    | 'erp-deposits'
    | 'erp-op-stages'
    | 'erp-products'
    | 'erp-drawn'
    | 'erp-stock-address'
    | 'hcm-cc'
    | 'hcm-devices'
    | 'erp-products-grouping'
    | 'erp-budgets'
    | 'eng-os-tasks'
    | 'eng-os'
    | 'custom';
  id?: string;
  noOptionsText?: string;
  label?: string;
  placeholder?: string;
  limitTags?: number;
  limit?: number;
  resultLimit?: number;
  customList?: ValueProps[];
  disabled?: boolean;
  searchParams?: Record<string, unknown>;
  multiple?: boolean;
  startAdornment?: React.ReactNode;
  error?: boolean;
  helperText?: string | undefined;
  required?: boolean;
}

let cancel: Canceler;
let userData: ValueProps = null;

function InputSearchAsync<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  id = '',
  onChange,
  value,
  source,
  noOptionsText = 'Nenhum resultado encontrado',
  label,
  placeholder,
  limitTags = Number.MAX_SAFE_INTEGER,
  resultLimit = 50,
  customList = [],
  disabled = false,
  searchParams = {},
  multiple = true,
  startAdornment = null,
  error = false,
  helperText = '',
  required = false,
  ...rest
}: InputSearchAsyncProps<T, Multiple, DisableClearable, FreeSolo>) {
  const values = value as ValueProps[];
  const classes = styles();
  const [inputText, setInputText] = useState('');
  const [inputTextSearch] = useDebounce(inputText, 300);
  const [responseData, setResponseData] = useState<ResponseType>(null);
  const [options, setOptions] = useState<ValueProps[]>(customList);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [disableInput, setDisableInput] = useState<boolean>(values?.length >= limitTags);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);

    let exclude = '';
    if (disableInput) {
      return;
    }
    if (loading) {
      cancel?.();
    }

    if (!open) {
      setOptions([]);
      return;
    }

    if (customList.length > 0) {
      setLoading(false);
      setOptions(customList.filter(f => values?.filter(v => v.id === f.id).length === 0));
    } else {
      (async () => {
        try {
          exclude = multiple ? `${values?.map((v: ValueProps) => `${v.id}`)}`.replaceAll(',', ';') : '';

          const params = {
            limit: resultLimit,
            page,
            source,
            search: inputTextSearch,
            exclude,
            searchParams: JSON.stringify(searchParams),
          };

          const response = await api.get<ResponseType>('search/input', {
            params,
            cancelToken: new Axios.CancelToken(c => {
              cancel = c;
            }),
          });
          // await sleep(1e3);
          const { data } = response;

          setResponseData(data);

          let newOptions = [] as ValueProps[];

          if (multiple) {
            if (value) {
              newOptions = values;
            }
            if (data) {
              newOptions = [...newOptions, ...data.results];
            }
          } else if (data) {
            newOptions = [...newOptions, ...data.results];
          }

          setOptions(newOptions);
        } catch (_error) {
          setOptions([]);
          console.error(_error);

          // console.error(error);
        }
        setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTextSearch, open]);

  useEffect(() => {
    setLoading(open);
    if (open && customList.length > 0) {
      setLoading(false);
    } else {
      setLoading(open);
    }
  }, [open]);

  useEffect(() => {
    setLoading(inputText !== inputTextSearch);
  }, [inputText, inputTextSearch]);

  async function handleLoadMore() {
    let exclude = '';
    if (!responseData) return undefined;
    if (page >= responseData?.page_total) return undefined;
    if (loadingMore) return undefined;

    setLoadingMore(true);

    try {
      exclude = multiple ? `${values?.map((v: ValueProps) => `${v.id}`)}`.replaceAll(',', ';') : '';
      setLoadingMore(true);
      const newPage = page + 1;
      const params = {
        limit: resultLimit,
        page: newPage,
        source,
        search: inputTextSearch,
        exclude,
        searchParams: JSON.stringify(searchParams),
      };

      setPage(newPage);
      const response = await api.get<ResponseType>('search/input', {
        params,
        cancelToken: new Axios.CancelToken(c => {
          cancel = c;
        }),
      });
      const { data } = response;
      setOptions([...options, ...data.results]);

      setLoadingMore(false);
    } catch (_error) {
      setLoadingMore(false);
      console.error(error);
    }

    return () => {
      setLoadingMore(false);
      cancel();
    };
  }

  const multipleAutocomplete = (
    <Autocomplete
      {...rest}
      id={id}
      size="small"
      multiple
      limitTags={limitTags}
      open={open}
      loading={loading}
      getOptionLabel={option => `${option?.description}`}
      options={options}
      autoComplete
      filterSelectedOptions
      filterOptions={x => x}
      includeInputInList
      autoHighlight
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      openText="Abrir"
      value={values}
      noOptionsText={loading ? 'Carregando...' : noOptionsText}
      disabled={disabled}
      onOpen={() => {
        if (values?.length >= limitTags) {
          return;
        }
        setOpen(values?.length < limitTags);
      }}
      onClose={() => {
        setOpen(false);
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const currentScrollPos = listboxNode.scrollTop + listboxNode.clientHeight;
          if (currentScrollPos === listboxNode.scrollHeight) {
            (async () => {
              try {
                await handleLoadMore();
                if (page < responseData?.page_total)
                  listboxNode.scrollTo(0, currentScrollPos - listboxNode.scrollTop - listboxNode.clientHeight);
                /*
                listboxNode.scrollTo(
                  0,
                  listboxNode.lastElementChild.clientHeight * (options.length - 1) - listboxNode.clientHeight,
                ); */
              } catch (_error) {
                // console.error(error);
              }
            })();
          }
        },
      }}
      getOptionDisabled={() => values?.length >= limitTags}
      onChange={(event: any, newValue: any) => {
        onChange(newValue);
        setDisableInput(newValue.length >= limitTags);
      }}
      onInputChange={(event, newInputValue) => {
        if (values?.length >= limitTags) {
          setInputText('');
        } else {
          if (newInputValue.length > 0) setLoading(true);
          setInputText(newInputValue);
        }
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            label={label}
            variant="outlined"
            placeholder={disableInput ? '' : placeholder}
            fullWidth
            required={required}
            InputProps={{
              ...params.InputProps,
              readOnly: disableInput,
              startAdornment: (
                <>
                  {startAdornment}
                  {params.InputProps.startAdornment}
                </>
              ),

              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: 32 }} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      renderOption={(renderProps, option, { inputValue }) => {
        const optionId = option?.id?.toString();
        const description = option?.description?.toString();
        const subdescription =
          option?.subdescription !== '' ? option?.subdescription?.toString() : `Código: ${optionId}`;
        const isLastId = options[(page - 1) * resultLimit - 7]?.id === optionId;
        return (
          <li {...renderProps} key={`multiple-key-${optionId}`}>
            <Box className={classes.resultContainer}>
              {source === 'intranet-users' && (
                <img
                  className={classes.resultUserImg}
                  src={avatarUrl(optionId)}
                  alt={description}
                  onError={(e: any) => {
                    e.target.src = avatarUrl(0);
                  }}
                />
              )}

              <Box className={classes.resultDescriptionContainer}>
                <Highlighter
                  className={classes.resultDescriptionText}
                  highlightClassName={classes.resultTextHighlights}
                  searchWords={inputValue.split(' ')}
                  autoEscape
                  textToHighlight={description}
                  sanitize={latinize}
                />

                <Highlighter
                  className={classes.resultSubDescriptionText}
                  highlightClassName={classes.resultTextHighlights}
                  searchWords={inputValue.split(' ')}
                  autoEscape
                  textToHighlight={subdescription ?? ''}
                  sanitize={latinize}
                />
              </Box>
            </Box>
            {loadingMore && isLastId && <CircularProgress color="inherit" size={20} style={{ marginRight: 32 }} />}
          </li>
        );
      }}
      renderTags={(v, getTagProps) => {
        if (!Array.isArray(v)) value = [];
        if (values?.length === 0) return <></>;

        return values?.map((option: ValueProps, index) => {
          const optionId = `${option?.id}`;
          const optionDescription = `${option?.description}`;
          const optionSubdescription = `${option?.subdescription}`;
          const key = `key-chip-${optionId}-${index}`;

          if (source === 'intranet-users') {
            return (
              <ProfileTooltip key={key} userId={optionId}>
                <Chip
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <img
                        className={classes.resultUserImgChip}
                        src={avatarUrl(optionId)}
                        alt={optionDescription}
                        onError={(e: any) => {
                          e.target.src = avatarUrl(0);
                        }}
                      />
                      <Typography variant="caption">{optionDescription}</Typography>
                    </Box>
                  }
                  {...getTagProps({ index })}
                />
              </ProfileTooltip>
            );
          }

          return (
            <Tooltip
              key={key}
              title={`${optionId} - ${optionId === optionDescription ? optionSubdescription : optionDescription}`}
            >
              <Chip label={optionDescription} {...getTagProps({ index })} />
            </Tooltip>
          );
        });
      }}
    />
  );

  const singleAutocomplete = (
    <Autocomplete
      {...rest}
      id={id}
      loading={loading}
      options={options}
      open={open}
      size="small"
      filterSelectedOptions
      filterOptions={x => x}
      includeInputInList
      autoComplete
      autoHighlight
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      openText="Abrir"
      value={value}
      clearOnEscape
      noOptionsText={loading ? 'Carregando...' : noOptionsText}
      disabled={disabled}
      getOptionLabel={option => `${option?.description}`}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setLoading(false);
      }}
      onChange={(event: any, newValue: any) => {
        if (source === 'intranet-users') {
          userData = newValue;
        }
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setLoading(true);
        setInputText(newInputValue);

        if (source === 'intranet-users') {
          if (newInputValue.length === 0) {
            userData = null;
          }
        }
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const currentScrollPos = listboxNode.scrollTop + listboxNode.clientHeight;
          if (currentScrollPos === listboxNode.scrollHeight) {
            (async () => {
              try {
                await handleLoadMore();
                if (page < responseData?.page_total)
                  listboxNode.scrollTo(0, currentScrollPos - listboxNode.scrollTop - listboxNode.clientHeight);
              } catch (error) {}
            })();
          }
        },
      }}
      renderInput={params => {
        let extra: React.ReactNode = null;

        if (source === 'intranet-users') {
          if (value === null) {
            userData = null;
          }
          const userId = userData?.id ?? 0;
          const userName = userData?.description ?? '';
          extra = (
            <ProfileTooltip userId={userId.toString()}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img
                  className={classes.resultUserImgChip}
                  src={avatarUrl(userId)}
                  alt={userName}
                  onError={(e: any) => {
                    e.target.src = avatarUrl(0);
                  }}
                />
              </Box>
            </ProfileTooltip>
          );
        }

        return (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            label={label}
            variant="outlined"
            placeholder={disableInput ? '' : placeholder}
            fullWidth
            required={required}
            InputProps={{
              ...params.InputProps,
              readOnly: disableInput,
              startAdornment: (
                <>
                  {startAdornment}
                  {source === 'intranet-users' && extra}
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: 32 }} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      renderOption={(renderProps, option, { inputValue }) => {
        const optionId = option?.id?.toString();
        const description = option?.description?.toString();
        const subdescription =
          option?.subdescription !== ''
            ? option?.subdescription?.toString()
            : optionId === description
              ? ''
              : `Código: ${optionId}`;
        const isLastId = options[(page - 1) * resultLimit - 7]?.id === optionId;
        const key = `single-key-${optionId}`;

        return (
          <li {...renderProps} key={key}>
            <Box className={classes.resultContainer}>
              {source === 'intranet-users' && (
                <img
                  className={classes.resultUserImg}
                  src={avatarUrl(optionId)}
                  alt={description}
                  onError={(e: any) => {
                    e.target.src = avatarUrl(0);
                  }}
                />
              )}

              <Box className={classes.resultDescriptionContainer}>
                <Highlighter
                  className={classes.resultDescriptionText}
                  highlightClassName={classes.resultTextHighlights}
                  searchWords={inputValue.split(' ')}
                  autoEscape
                  textToHighlight={description}
                  sanitize={latinize}
                />

                <Highlighter
                  className={classes.resultSubDescriptionText}
                  highlightClassName={classes.resultTextHighlights}
                  searchWords={inputValue.split(' ')}
                  autoEscape
                  textToHighlight={subdescription ?? ''}
                  sanitize={latinize}
                />
              </Box>
            </Box>
            {loadingMore && isLastId && <CircularProgress color="inherit" size={20} style={{ marginRight: 32 }} />}
          </li>
        );
      }}
    />
  );

  return <>{multiple ? multipleAutocomplete : singleAutocomplete}</>;
}

export default InputSearchAsync;
