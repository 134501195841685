import React from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './styles';

export default function RequestsNew() {
  const classes = styles();

  return (
    <Box className={classes.root}>
      <Paper className={classes.tableContainer}>
        <Typography>Requisições Pendentes</Typography>
        <Box className={classes.table}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Header</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    Row
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      <Paper className={classes.actionContainer}>
        <Tooltip title="Gerar Requesição" aria-label="new-request">
          <Button color="primary" variant="contained" component={Link} to="/erp/stock/request/add">
            GERAR
          </Button>
        </Tooltip>
        <Tooltip title="Pesquisar Requesições" aria-label="search-request">
          <Button color="primary" variant="contained" component={Link} to="/erp/stock/request/search">
            PESQUISAR
          </Button>
        </Tooltip>
      </Paper>
    </Box>
  );
}
