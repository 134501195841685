import React, { useEffect, useMemo, useState } from 'react';

import { Box, Button, Chip, ChipPropsColorOverrides, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { HelpDeskStatusType } from '../..';
import api from '../../../../../services/api';
import ProfileTooltip from '../../../../../components/ProfileTooltip';
import { avatarUrl } from '../../../../../utils/functions';

interface IHelpdeskRequests {}

function RenderStatus(status: HelpDeskStatusType) {
  const isLate = status?.includes('_late');

  const label = {
    canceled: 'Cancelado',
    canceled_late: 'Cancelado',
    ended: 'Finalizado',
    ended_late: 'Finalizado',
    not_started: 'Não iniciado',
    not_started_late: 'Não iniciado',
    started: 'Andamento',
    started_late: 'Andamento',
  };

  const color: ChipPropsColorOverrides = {
    canceled: 'error',
    canceled_late: 'error',
    ended: 'success',
    ended_late: 'success',
    not_started: 'warning',
    not_started_late: 'warning',
    started: 'info',
    started_late: 'info',
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Chip size="small" label={label[status]} variant="outlined" color={color[status]} />
      {isLate ? (
        <Chip size="small" label="Atrasado" variant="outlined" color="error" />
      ) : (
        <Chip size="small" label="No prazo" variant="outlined" color="success" />
      )}
    </Box>
  );
}

function HelpdeskRequests() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = React.useState({
    current_page: 1,
    data_limit: 10,
    order_dir: 'asc',
    order_by: 'id',
    filter_field: '',
    filter_operator: '',
    filter_value: '',
    data_total: 0,
    total_page: 1,
    data: [],
  });

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 15,
  });

  const [filterModel, setFilterModel] = React.useState({ items: [] });
  const [sortModel, setSortModel] = React.useState([]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Nº',
      width: 90,
      type: 'number',
      renderCell: params => (
        <Tooltip title="Visualizar Helpdesk" disableInteractive arrow>
          <Button component={Link} to={`/helpdesk/${params?.value}`} target="_blank" rel="noopener noreferrer">
            {params?.value}
          </Button>
        </Tooltip>
      ),
    },
    { field: 'target_description', headerName: 'Setor', width: 160 },
    { field: 'title', headerName: 'Assunto', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Data Criação',
      type: 'dateTime',
      width: 160,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'attendant_id',
      headerName: 'Atendente',
      width: 200,
      renderCell: params => {
        return (
          params.row.attendant_name && (
            <ProfileTooltip userId={params?.value.toString()} openNewTab>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                  }}
                  src={avatarUrl(params.value)}
                  alt={params.row.attendant_name || params.value}
                  onError={(e: any) => {
                    e.target.src = avatarUrl(0, true);
                  }}
                />
                <Typography color={theme.palette.text.primary} sx={{ textDecoration: 'none' }} variant="inherit">
                  {params.row.attendant_name || ' '}
                </Typography>
              </Box>
            </ProfileTooltip>
          )
        );
      },
    },
    {
      field: 'status',
      headerName: 'Situação',
      width: 200,
      renderCell: params => {
        if (!params.value) {
          return '';
        }
        return RenderStatus(params.value);
      },
    },
  ];

  const fetcher = async () => {
    try {
      if (isFetching) return;
      setIsFetching(true);
      const { data: helpdesk } = await api.get('v2/helpdesk/list/requests', {
        params: {
          current_page: paginationModel.page,
          data_limit: paginationModel.pageSize,
          order_by: sortModel?.[0]?.field || 'id',
          order_dir: sortModel?.[0]?.sort || 'asc',
          filter_field: filterModel?.items?.[0]?.field || 'id',
          filter_operator: filterModel?.items?.[0]?.operator || '=',
          filter_value: filterModel?.items?.[0]?.value || '',
          filter_type: columns.find(c => c.field === filterModel?.items?.[0]?.field)?.type || 'string',
        },
      });

      setData(helpdesk);
      console.log(helpdesk);
    } catch (error) {
      console.error(error);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetcher();
  }, [paginationModel, sortModel, filterModel]);

  const renderDataGrid = useMemo(() => {
    return (
      <DataGrid
        rows={data?.data || []}
        columns={columns}
        pagination
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={setFilterModel}
        loading={isFetching}
        rowCount={data?.data_total || 0}
        paginationModel={paginationModel}
        disableRowSelectionOnClick
      />
    );
  }, [data?.data, isFetching]);
  return (
    <Box>
      <Box sx={{ height: 'calc(100vh - 150px)', width: '100%' }}>{renderDataGrid}</Box>
    </Box>
  );
}

export { HelpdeskRequests };
