import React from 'react';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { RequestsList } from './RequestsList';

export default function RequestsDashboard() {
  return (
    <Box sx={{ display: 'flex', gap: 2, flex: 1, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Tooltip title="Gerar Requesição" aria-label="new-request">
          <Button color="success" variant="outlined" component={Link} to="/erp/stock/request/add">
            GERAR
          </Button>
        </Tooltip>
        <Tooltip title="Pesquisar Requesições" aria-label="search-request">
          <Button color="primary" variant="outlined" component={Link} to="/erp/stock/request/search">
            PESQUISAR
          </Button>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        <Typography>Minhas Requisições Pendentes</Typography>
        <Paper sx={{ display: 'flex', gap: 2, p: 2, flex: 1, flexDirection: 'column' }}>
          <RequestsList pending />
          <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
            <Box sx={{ flex: 1 }} />

            <Button color="warning" variant="outlined" component={Link} to="/erp/stock/request/search">
              Ver todas
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        <Typography>Minhas Requisições Atendidas</Typography>
        <Paper sx={{ display: 'flex', gap: 2, p: 2, flex: 1, flexDirection: 'column' }}>
          <RequestsList attended />

          <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
            <Box sx={{ flex: 1 }} />

            <Button color="warning" variant="outlined" component={Link} to="/erp/stock/request/search">
              Ver todas
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
