import React from 'react';
import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import Admin from './admin';
import Authenticate from './authenticate';
import Contacts from './contacts';
import Dashboard from './dashboard';
import Manager from './manager';
import Os from './os';
import Erp from './erp';
import Hcm from './hcm';
import Helpdesk from './helpdesk';
import Helpdeskv2 from './helpdesk/v2';
import It from './it';
import Messages from './messages';
import News from './news';
import Kanban from './kanban';
import Profile from './profile';
import Templates from './templates';
import Files from './files';
import Gallery from './gallery';
import { UserDataProps } from '../routes';

export { Admin, Dashboard, Erp, Hcm, Helpdesk, It, Messages, News, Profile, Templates, Authenticate, Files };
export type BreadcrumbsType = {
  name: string;
  path: string;
};

function ErrorPage() {
  return <div>error</div>;
}

export const breadcrumbs = [
  {
    path: 'news',
    breadcrumbs: [{ name: 'Notícias', path: '/news' }],
  },
  {
    path: 'news/add',
    breadcrumbs: [
      { name: 'Notícias', path: '/news' },
      { name: 'Nova', path: '' },
    ],
  },
  {
    path: 'news/published',
    breadcrumbs: [
      { name: 'Notícias', path: '/news' },
      { name: 'Publicações', path: '' },
    ],
  },
  {
    path: 'news/:id/edit',
    breadcrumbs: [
      { name: 'Notícias', path: '/news' },
      { name: ':id', path: '/news/:id' },
      { name: 'Editar', path: '' },
    ],
  },
  {
    path: 'news/:id',
    breadcrumbs: [
      { name: 'Notícias', path: '/news' },
      { name: ':id', path: '' },
    ],
  },
  {
    path: 'contacts',
    breadcrumbs: [{ name: 'Contatos', path: '' }],
  },
  {
    path: 'gallery/:id/edit',
    breadcrumbs: [{ name: 'Galeria', path: '' }],
  },
  {
    path: 'gallery/add',
    breadcrumbs: [{ name: 'Galeria', path: '' }],
  },
  {
    path: 'gallery/:id',
    breadcrumbs: [{ name: 'Galeria', path: '' }],
  },
  {
    path: 'gallery',
    breadcrumbs: [{ name: 'Galeria', path: '' }],
  },
  {
    path: 'profile/edit',
    breadcrumbs: [
      { name: 'Perfil', path: '/profile' },
      { name: 'Editar', path: '' },
    ],
  },
  {
    path: 'profile/:id',
    breadcrumbs: [
      { name: 'Perfil', path: '/profile' },
      { name: ':id', path: '' },
    ],
  },
  {
    path: 'profile',
    breadcrumbs: [{ name: 'Perfil', path: '' }],
  },
  {
    path: 'helpdesk/v2',
    breadcrumbs: [{ name: 'Helpdesk', path: '' }],
  },
  {
    path: 'helpdesk/add',
    breadcrumbs: [
      { name: 'Helpdesk', path: '/helpdesk' },
      { name: 'Abertura', path: '' },
    ],
  },
  {
    path: 'helpdesk/attendance',
    breadcrumbs: [
      { name: 'Helpdesk', path: '/helpdesk' },
      { name: 'Atendimento', path: '' },
    ],
    props: { search: { situation: '0;1' } },
  },
  {
    path: 'helpdesk/search',
    breadcrumbs: [
      { name: 'Helpdesk', path: '/helpdesk' },
      { name: 'Pesquisar', path: '' },
    ],
  },
  {
    path: 'helpdesk/pending',
    breadcrumbs: [
      { name: 'Helpdesk', path: '/helpdesk' },
      { name: 'Pendentes', path: '' },
    ],
    props: { search: { situation: '0;1' } },
  },
  {
    path: 'helpdesk/finalized',
    breadcrumbs: [
      { name: 'Helpdesk', path: '/helpdesk' },
      { name: 'Finalizados', path: '' },
    ],
    props: { search: { situation: '2;3' } },
  },
  {
    path: 'helpdesk/reports',
    breadcrumbs: [
      { name: 'Helpdesk', path: '/helpdesk' },
      { name: 'Relatórios', path: '' },
    ],
  },
  {
    path: 'helpdesk/statistics',
    breadcrumbs: [
      { name: 'Helpdesk', path: '/helpdesk' },
      { name: 'Estatísticas', path: '' },
    ],
  },
  {
    path: 'helpdesk/:id',
    breadcrumbs: [
      { name: 'Helpdesk', path: '/helpdesk' },
      { name: ':id', path: '' },
    ],
  },
  {
    path: 'helpdesk',
    breadcrumbs: [{ name: 'Helpdesk', path: '' }],
  },
  {
    path: 'os',
    breadcrumbs: [{ name: 'OS', path: '' }],
  },
  {
    path: 'admin/contacts',
    breadcrumbs: [
      { name: 'Admin', path: '' },
      { name: 'Contatos', path: '' },
    ],
  },
  {
    path: 'admin/users',
    breadcrumbs: [
      { name: 'Admin', path: '' },
      { name: 'Usuários', path: '' },
    ],
  },
  {
    path: 'admin/users/add',
    breadcrumbs: [
      { name: 'Admin', path: '' },
      { name: 'Usuários', path: '/admin/users' },
      { name: 'Novo', path: '' },
    ],
  },
  {
    path: 'admin/users/:id',
    breadcrumbs: [
      { name: 'Admin', path: '' },
      { name: 'Usuários', path: '/admin/users' },
      { name: ':id', path: '' },
    ],
  },
  {
    path: 'admin/helpdesk',
    breadcrumbs: [
      { name: 'Admin', path: '' },
      { name: 'Helpdesk', path: '' },
    ],
  },
  {
    path: 'admin/configs/sidebar',
    breadcrumbs: [
      { name: 'Admin', path: '' },
      { name: 'Helpdesk', path: '' },
    ],
  },
  {
    path: 'admin/permissions',
    breadcrumbs: [
      { name: 'Admin', path: '' },
      { name: 'Permissões', path: '' },
    ],
  },
  {
    path: 'admin/hcm/menu',
    breadcrumbs: [
      { name: 'Admin', path: '' },
      { name: 'HCM', path: '' },
      { name: 'Cardápio', path: '' },
    ],
  },
  {
    path: 'admin',
    breadcrumbs: [{ name: 'Admin', path: '' }],
  },
  {
    path: 'manager/vacation/calendar',
    breadcrumbs: [
      { name: 'Gestor', path: '/manager' },
      { name: 'Férias', path: '/manager' },
      { name: 'Calendário', path: '' },
    ],
  },
  {
    path: 'manager/accesses',
    breadcrumbs: [
      { name: 'Gestor', path: '/manager' },
      { name: 'Acessos', path: '' },
    ],
  },
  {
    path: 'manager/dayoff/add',
    breadcrumbs: [
      { name: 'Gestor', path: '/manager' },
      { name: 'Folgas', path: '' },
      { name: 'Adicionar', path: '' },
    ],
  },
  {
    path: 'manager',
    breadcrumbs: [
      { name: 'Gestor', path: '' },
      { name: 'Dashboard', path: '' },
    ],
  },
  {
    path: 'hcm/payday',
    breadcrumbs: [
      { name: 'HCM', path: '' },
      { name: 'Pagamentos', path: '' },
    ],
  },
  {
    path: 'hcm/birthday',
    breadcrumbs: [
      { name: 'HCM', path: '' },
      { name: 'Aniversáriantes', path: '' },
    ],
  },
  {
    path: 'hcm/my-accesses',
    breadcrumbs: [
      { name: 'HCM', path: '' },
      { name: 'Meus Acessos', path: '' },
    ],
  },
  {
    path: 'hcm/menu',
    breadcrumbs: [
      { name: 'HCM', path: '' },
      { name: 'Cardápios', path: '' },
    ],
  },
  {
    path: 'hcm',
    breadcrumbs: [{ name: 'HCM', path: '' }],
  },
  {
    path: 'erp/orders',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Pedidos', path: '' },
    ],
  },
  {
    path: 'erp/orders/follow-up',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Pedidos', path: '/erp/orders' },
      { name: 'Follow UP', path: '' },
    ],
  },
  {
    path: 'erp/orders/search',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Pedidos', path: '/erp/orders' },
      { name: 'Pesquisar', path: '' },
    ],
  },
  {
    path: 'erp/orders/:id',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Pedidos', path: '/erp/orders' },
      { name: ':id', path: '' },
    ],
  },
  {
    path: 'erp/stock/search',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Estoque', path: '' },
      { name: 'Consultar', path: '' },
    ],
  },
  {
    path: 'erp/stock/request/add',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Estoque', path: '' },
      { name: 'Requisição', path: '/erp/stock/request' },
      { name: 'Gerar', path: '' },
    ],
  },
  {
    path: 'erp/stock/request/search',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Estoque', path: '' },
      { name: 'Requisição', path: '/erp/stock/request' },
      { name: 'Pesquisar', path: '' },
    ],
  },
  {
    path: 'erp/stock/request/:id',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Estoque', path: '' },
      { name: 'Requisição', path: '/erp/stock/request' },
      { name: ':id', path: '' },
    ],
  },
  {
    path: 'erp/stock/request',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Estoque', path: '' },
      { name: 'Requisição', path: '' },
    ],
  },
  {
    path: 'erp/stock/level',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Estoque', path: '' },
      { name: 'Nível', path: '' },
    ],
  },
  {
    path: 'erp/orders/overview',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Pedidos', path: '/erp/orders' },
      { name: 'Carteira', path: '' },
    ],
  },
  {
    path: 'erp/op/productivity',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'OP', path: '' },
      { name: 'Produtividate', path: '' },
    ],
  },
  {
    path: 'erp/op/schedule',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'OP', path: '' },
      { name: 'Programação', path: '' },
    ],
  },
  {
    path: 'erp/production/dashboard',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Produção', path: '' },
      { name: 'Dashboard', path: '' },
    ],
  },
  {
    path: 'erp/engineering/os',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Engenharia', path: '' },
      { name: 'OS', path: '' },
    ],
  },
  {
    path: 'erp/engineering/os/:id',
    breadcrumbs: [
      { name: 'ERP', path: '' },
      { name: 'Engenharia', path: '' },
      { name: 'OS', path: '/erp/engineering/os' },
      { name: ':id', path: '' },
    ],
  },
  {
    path: 'it/tools/erp-connections',
    breadcrumbs: [
      { name: 'TI', path: '' },
      { name: 'Ferramentas', path: '' },
      { name: 'Conexões ERP', path: '' },
    ],
  },
  {
    path: 'files/download/:id',
    breadcrumbs: [
      { name: 'Arquivos', path: '/files' },
      { name: ':id', path: '' },
    ],
  },
  {
    path: 'messages',
    breadcrumbs: [{ name: 'Mensagens', path: '' }],
  },
  {
    path: 'dashboard',
    breadcrumbs: [{ name: 'Dashboard', path: '' }],
  },
] as {
  path: string;
  breadcrumbs: { name: string; path: string }[];
  props?: any;
}[];

export const routerList: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/dashboard" replace />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'kanban',
    element: <Kanban.Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'news',
    element: <News.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'news/add',
    element: <News.Add />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'news/published',
    element: <News.Published />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'news/:id/edit',
    element: <News.Edit />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'news/:id',
    element: <News.View />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'contacts',
    element: <Contacts />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'gallery/:id/edit',
    element: <Gallery.Edit />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'gallery/add',
    element: <Gallery.Add />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'gallery/:id',
    element: <Gallery.View />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'gallery',
    element: <Gallery.Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'profile/edit',
    element: <Profile.Edit />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'profile/:id',
    element: <Profile.View />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'profile',
    element: <Profile.View />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/v2',
    element: <Helpdeskv2.Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/add',
    element: <Helpdesk.Add />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/attendance',
    element: <Helpdesk.Attendance search={{ situation: '0;1' }} />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/search',
    element: <Helpdesk.Search />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/pending',
    element: <Helpdesk.Pending search={{ situation: '0;1' }} />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/finalized',
    element: <Helpdesk.Pending search={{ situation: '2;3' }} />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/reports',
    element: <Helpdesk.Reports />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/statistics',
    element: <Helpdesk.Statistics />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk/:id',
    element: <Helpdesk.View />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'helpdesk',
    element: <Helpdesk.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'os',
    element: <Os.Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin/contacts',
    element: <Admin.Contacts />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin/users',
    element: <Admin.Users.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin/users/add',
    element: <Admin.Users.Add />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin/users/:id',
    element: <Admin.Users.Edit />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin/helpdesk',
    element: <Admin.Helpdesk />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin/configs/sidebar',
    element: <Admin.Helpdesk />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin/permissions',
    element: <Admin.Permissions />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin/hcm/menu',
    element: <Admin.Hcm.Menu />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'admin',
    element: <Admin.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'manager/vacation/calendar',
    element: <Manager.Vacation.Calendar />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'manager/accesses',
    element: <Manager.Accesses />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'manager/dayoff/add',
    element: <Manager.Dayoff.Add />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'manager',
    element: <Manager.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'hcm/payday',
    element: <Hcm.Payday />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'hcm/birthday',
    element: <Hcm.Birthday />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'hcm/my-accesses',
    element: <Hcm.Myaccesses />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'hcm/menu',
    element: <Hcm.Menu />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'hcm',
    element: <Hcm.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/orders',
    element: <Erp.Orders.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/orders/follow-up',
    element: <Erp.Orders.FollowUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/orders/search',
    element: <Erp.Orders.Search />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/orders/:id',
    element: <Erp.Orders.View />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/stock/search',
    element: <Erp.Stock.Search />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/stock/request/add',
    element: <Erp.Stock.Request.Add />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/stock/request/search',
    element: <Erp.Stock.Request.Search />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/stock/request/:id',
    element: <Erp.Stock.Request.View />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/stock/request',
    element: <Erp.Stock.Request.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/stock/level',
    element: <Erp.Stock.Level />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/orders/overview',
    element: <Erp.Orders.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/op/productivity',
    element: <Erp.Production.Monitor />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/op/schedule',
    element: <Erp.Production.Schedule />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/production/dashboard',
    element: <Erp.Production.Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/engineering/os',
    element: <Erp.Engineering.Os.Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'erp/engineering/os/:id',
    element: <Erp.Engineering.Os.View />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'it/tools/erp-connections',
    element: <It.Tools.ErpConnections />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'files/download/:id',
    element: <Files.Download />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'messages',
    element: <Messages />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
];

export const routers = createBrowserRouter(routerList);
